<template>
  <div class="card mb-5">
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div
            class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
          >
            <img :src="user.avatar ? user.avatar : `/media/avatars/blank.png`" alt="image"/>
            <div
              class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"
            ></div>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="d-flex justify-content-between align-items-start flex-wrap mb-2"
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                >{{user.firstName + user.lastName}}</a
                >
                <a href="#">
                  <span class="svg-icon svg-icon-1 svg-icon-primary">
                    <inline-svg src="/media/icons/duotune/general/gen026.svg"/>
                  </span>
                </a>
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">


                <a
                  href="#"
                  class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="media/icons/duotune/communication/com011.svg"
                    />
                  </span>
                  {{user.name}}
                </a>
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->

            <!--begin::Actions-->

            <!--end::Actions-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->
      <!--begin::Navs-->
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
        >
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              to="/profile/overview"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Overview
            </router-link>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              to="/profile/settings"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Settings
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/profile/theme"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Theme
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/profile/change-password"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Change Password
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/profile/analytics"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Analytics
            </router-link>
          </li>
          <!--end::Nav item-->
        </ul>
      </div>
      <!--begin::Navs-->
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import {computed, onMounted} from "vue";

import {setCurrentPageBreadcrumbsWithParams, setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {useStore} from "vuex";

export default {
  name: "Profile",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Profile")
    })
    const store = useStore();
    const user = computed(() => store.getters.currentUser);
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Profile", [])
    })

    return {
      user,
    }
  }
}
</script>

<style scoped>

</style>